import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from '../components/Section';
import Typography, {
	Heading1,
	Heading2,
	Paragraph,
} from '../components/Typography';
import HeroSection from '../components/HeroSection';
import Media from '../components/Media';
import Flexbox from '../components/Flexbox';
import Card from '../components/Card';
import UnstyledLink from '../components/Link';
import Grid from '../components/Grid';

import clientConnect from '../images/client-connect-logo.svg';
import clientWeb from '../images/client-web-logo.svg';

const LoginButtonContainer = styled(UnstyledLink)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 200px;
	width: auto;
	border-radius: 2px;
	transition: all 0.3s ease-in-out;
	background-color: #e6eaff;
	cursor: pointer;
	text-decoration: none;

	:hover {
		box-shadow: 0px 6px 8px rgba(122, 134, 188, 0.4);
		transform: translateY(-2px);
	}
`;

const LoginButton = ({ content, iconSrc, href, openModal }) => (
	<div onClick={openModal}>
		<LoginButtonContainer href={href}>
			<Flexbox
				alignItems='center'
				flexDirection='column'
				justifyContent='space-between'
			>
				<Media src={iconSrc} />
				<Typography
					color='text-grey'
					fontSize='18px'
					fontWeight='500'
					lineHeight='26px'
					textTransform='uppercase'
				>
					{content}
				</Typography>
			</Flexbox>
		</LoginButtonContainer>
	</div>
);

LoginButton.propTypes = {
	content: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	iconSrc: PropTypes.string.isRequired,
};

const CouncilClientLoginCard = ({ openModal }) => (
	<Card
		paddingTop='40px'
		paddingRight='24px'
		paddingLeft='24px'
		paddingBottom='20px'
		height='100%'
		width='100%'
	>
		<Heading2 color='text-darkblue'>Client / Council Login Area</Heading2>

		<Paragraph>
			Click the links below to access the relevant service.
		</Paragraph>

		<Grid
			gridTemplateRows={useMemo(
				() => ({
					xs: 'auto auto auto',
					lg: 'auto',
				}),
				[]
			)}
			gridTemplateColumns={useMemo(
				() => ({
					xs: '1fr',
					lg: '1fr 1fr',
				}),
				[]
			)}
			gridGap='20px'
		>
			<LoginButton
				content='Client connect'
				iconSrc={clientConnect}
				href='https://www.jacobsenforcement.com/aneris/external/public/connect/client'
			/>
			<LoginButton
				content='Client web'
				iconSrc={clientWeb}
				href='https://clientweb.jacobsenforcement.com/ClientWebv7/'
			/>
		</Grid>
	</Card>
);

const LoginPage = () => {

	return (
		<>
			<HeroSection paddingTop='8px'>
				<Heading1 marginTop='120px' maxWidth='800px'>
					Account Login
				</Heading1>
			</HeroSection>
			<Section
				backgroundColor='grey'
				paddingTop='78px'
				paddingBottom='70px'
			>
				<Grid
					gridGap='2rem'
					gridTemplateRows={{
						xs: 'auto auto',
						lg: 'auto',
					}}
					gridTemplateColumns={{
						xs: '1fr',
						lg: '1fr 0.75fr',
					}}
					justifyItems='stretch'
				>
					<CouncilClientLoginCard  />
				</Grid>
			</Section>
		</>
	);
};

export default LoginPage;
